import * as React from "react";
import { Constants } from "../../core/constants";
import { label, labelParam } from "../../core/global";
import { Button } from "../../components/form/button/button";
import { App } from "../../core/app";
import "./landing.scss";
import { Icon } from "../../components/icon/icon";
import { AnimatedLines } from "../../components/animatedLines/animatedLines";
import SignupRoutes from "./signupRoutes";
import Tracking from "../../core/tracking";
import { Environment, getEnvironment } from "../../utils/env";

const ExternalRoutingCard = (): JSX.Element => {
  return (
    <div className="app-card external-routing">
      <div className="icon-wrapper small">
        <Icon name={"menu-external-routing"} />
      </div>
      <div className="title">{label.networkInsights}</div>
      <div className="text">{label.externalRoutingDecription}</div>
      <div className="actions">
        <a
          href={Constants.EXTERNALROUTING_VIDEO}
          className="btn video"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon name={"reel"} />
          {label.watchVideo}
        </a>
        <a
          href={Constants.EXTERNALROUTING_DATASHEET}
          className="btn info"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon name={"obj-report"} />
          {label.moreInformation}
        </a>
      </div>
    </div>
  );
};

const Footer = (): JSX.Element => {
  return (
    <section className="footer">
      <div className="copyright-text">{Landing.getFooterYearValue()}</div>
      <div className="footer-nav">
        <a
          href={Constants.XWC_CONTACTS}
          target="_blank"
          rel="noopener noreferrer"
        >
          {label.contacts}
        </a>
        <a
          href={Constants.XWC_FINDRESELLER}
          target="_blank"
          rel="noopener noreferrer"
        >
          {label.resellers}
        </a>
        <a href={Constants.XWC_HELP} target="_blank" rel="noopener noreferrer">
          {label.documentation}
        </a>
        <a
          href={Constants.XWC_TERMS_CONDITIONS}
          target="_blank"
          rel="noopener noreferrer"
        >
          {label.termsAndConditions}
        </a>
        <a
          href={Constants.XWC_PRIVACY_STATEMENT}
          target="_blank"
          rel="noopener noreferrer"
        >
          {label.privacyStatement}
        </a>
      </div>
    </section>
  );
};

interface DefaultLandingProps {
  handleCreateAccount: () => void;
  handleGoToDashboardClick: () => void;
}

const DefaultLanding: React.FC<DefaultLandingProps> = ({
  handleCreateAccount,
  handleGoToDashboardClick,
  ...rest
}): JSX.Element => {
  return (
    <>
      <div className="marketing-page full-page">
        <AnimatedLines />
        <div className="wrapper">
          <section className="header">
            <Icon name={"cisco-logo"} />
            <div className="crosswork">
              <span>{label.crosswork}</span>
              {label.cloud}
            </div>
            <div className="actions">
              <span className="actions-tag">
                {label.newToCrossworkCloudMessage}
              </span>
              <Button
                type="submit"
                classNames="secondary"
                name="createAccount"
                onClick={handleCreateAccount}
              >
                {label.signUp}
              </Button>
              <Button
                type="submit"
                classNames="primary"
                name="login"
                onClick={handleGoToDashboardClick}
              >
                <span>{label.login}</span>
              </Button>
            </div>
          </section>
          <section className="content">
            <div className="app-info">
              <div className="app-icon">
                <div className="icon-wrapper">
                  <Icon name={"menu-external-routing"} />
                </div>
                <div className="icon-wrapper traffic">
                  <Icon name={"menu-traffic-analysis"} />
                </div>
                <div className="icon-wrapper trust">
                  <Icon name={"menu-trust-insights"} />
                </div>
              </div>
              <div className="crosswork">
                <span>{label.crosswork}</span>
                {label.cloud}
              </div>
              <div className="description">{label.crossworkBannerText}</div>
            </div>
            <div className="lines">
              <div className="divide top">
                <div className="line" />
                <div className="line" />
                <div className="line" />
                <div className="line" />
                <div className="line" />
                <div className="line" />
              </div>
              <div className="divide bottom">
                <div className="line" />
                <div className="line" />
                <div className="line" />
                <div className="line" />
                <div className="line" />
                <div className="line" />
              </div>
            </div>
            <div className="cards">
              <ExternalRoutingCard />
              <div className="app-card traffic">
                <div className="icon-wrapper small traffic">
                  <Icon name={"menu-traffic-analysis"} />
                </div>
                <div className="title">{label.trafficAnalysis}</div>
                <div className="text">{label.trafficDecription}</div>
                <div className="actions">
                  <a
                    href={Constants.TRAFFICANALYSIS_VIDEO}
                    className="btn video"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Icon name={"reel"} />
                    {label.watchVideo}
                  </a>
                  <a
                    href={Constants.TRAFFICANALYSIS_DATASHEET}
                    className="btn info"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Icon name={"obj-report"} />
                    {label.moreInformation}
                  </a>
                </div>
              </div>
              <div className="app-card trust">
                <div className="icon-wrapper small trust">
                  <Icon name={"menu-trust-insights"} />
                </div>
                <div className="title">{label.trustInsights}</div>
                <div className="text">{label.trustDecription}</div>
                <div className="actions">
                  <a
                    href={Constants.TRUSTINSIGHTS_VIDEO}
                    className="btn video"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Icon name={"reel"} />
                    {label.watchVideo}
                  </a>
                  <a
                    href={Constants.TRUSTINSIGHTS_DATASHEET}
                    className="btn info"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Icon name={"obj-report"} />
                    {label.moreInformation}
                  </a>
                </div>
              </div>
            </div>
            <div className="bgpmon-wrapper">
              <div className="content-wrapper">
                <div className="text-block">
                  <div className="bgpmon">
                    <span>{label.bgp}</span>
                    {label.mon}
                  </div>
                  <div className="text">{label.bgpMonDescription}</div>
                </div>
                <button className="bgpmon-btn" onClick={handleCreateAccount}>
                  {label.signUp}
                </button>
              </div>
            </div>
          </section>
          <Footer />
        </div>
      </div>
      <Tracking {...rest} />
    </>
  );
};

interface BGPMonLandingProps {
  createUserUrl: string | undefined;
  handleLogin: () => void;
  handleCreateAccount: () => void;
}

const BGPMonLanding: React.FC<BGPMonLandingProps> = ({
  createUserUrl,
  handleLogin,
  handleCreateAccount,
  ...rest
}): JSX.Element => {
  return (
    <>
      <div className="marketing-page full-page bgpmon">
        <AnimatedLines />
        <div className="wrapper">
          <section className="header">
            <Icon name={"cisco-logo"} />
            <div className="actions">
              <div className="bgpmon-tag">
                <span className="tag1">
                  <b>BGP</b>
                  Mon is Now Part of
                </span>
                <span className="tag2">
                  <b>{label.crosswork}</b>
                  {label.cloud}
                </span>
              </div>
              <Button
                type="submit"
                classNames="secondary"
                name="createAccount"
                onClick={handleCreateAccount}
              >
                {label.signUp}
              </Button>
              <Button
                type="submit"
                classNames="primary"
                name="login"
                onClick={handleLogin}
              >
                <span>{label.login}</span>
              </Button>
            </div>
          </section>
          <section className="bgpmon-content">
            <div className="bgpmon-title">
              <h3>
                <b>BGP</b>
                Mon is Now Part of
              </h3>
              <h1>
                <b>{label.crosswork}</b>
                {label.cloud}
              </h1>
            </div>
            <div className="bgpmon-desc">
              <p>{label.bgpMonEOLHint}</p>
              <p>{label.bgpMonReplacementHint}</p>
              <p>{label.bgpMonCreateCCNAccountHint}</p>
            </div>
            <div className="create-account">
              {label.needToCreateCCOAccount}
              {createUserUrl && (
                <>
                  <a
                    href={createUserUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="link link-description"
                  >
                    {label.here}
                  </a>
                  {"."}
                </>
              )}
            </div>
            <div className="ask-assistance">
              {label.xwCloudAskAssistanceLine1}
              <a
                href={`mailto:${Constants.XW_CLOUD_ASK_ASSISTANCE_EMAIL}?subject=${label.xwCloudAskAssistanceEmailSubject}`}
              >
                {Constants.XW_CLOUD_ASK_ASSISTANCE_EMAIL}
              </a>
              {"."}
            </div>
            <div className="cards">
              <ExternalRoutingCard />
            </div>
          </section>
          <Footer />
        </div>
      </div>
      <Tracking {...rest} />
    </>
  );
};

interface Props {
  route?: pages;
}

type pages = "dashboard" | "signup" | "landing" | "bgpmon";
interface State {
  selectedRoute: pages;
  createUserUrl: string | undefined;
}
export class Landing extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedRoute: this.props.route || "landing",
      createUserUrl: undefined
    };
  }

  static getFooterYearValue(): string {
    const year = new Date().getFullYear();
    return labelParam(label.footer, { year });
  }

  componentDidMount(): void {
    const { route } = this.props;
    if (route === "bgpmon") {
      getEnvironment().then((environment: Environment) => {
        this.setState({
          createUserUrl: environment.enroll_url
        });
      });
    }
  }

  handleGoToDashboardClick = (): void => {
    this.setState({ selectedRoute: "dashboard" });
  };

  handleLogin = (): void => {
    this.setState({ selectedRoute: "dashboard" }, () => {
      window.location.hash = "";
    });
  };

  handleCreateAccount = (): void => {
    this.setState({ selectedRoute: "signup" }, () => {
      window.location.hash = "#/signup";
    });
  };

  render(): JSX.Element {
    const {
      handleLogin,
      handleCreateAccount,
      handleGoToDashboardClick,
      props,
      state
    } = this;
    const { selectedRoute, createUserUrl } = state;
    switch (selectedRoute) {
      case "dashboard":
        return <App />;
      case "signup":
        return <SignupRoutes />;
      case "bgpmon":
        return (
          <BGPMonLanding
            handleLogin={handleLogin}
            handleCreateAccount={handleCreateAccount}
            createUserUrl={createUserUrl}
            {...props}
          />
        );
      case "landing":
      default:
        return (
          <DefaultLanding
            handleGoToDashboardClick={handleGoToDashboardClick}
            handleCreateAccount={handleCreateAccount}
            {...props}
          />
        );
    }
  }
}
